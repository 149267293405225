import React from "react";
import { Link } from "gatsby";
import * as projectStyles from "./Project.module.scss";

function Project({
  node: {
    title,
    gitHub,
    demoLink,
    description: { description },
    image,
  },
}) {
  return (
    <div className={projectStyles.project_card}>
      <h2>{title}</h2>
      <p>
        <a href={gitHub} target="blank">
          Source Code And Details
        </a>{" "}
      </p>
      <Link to={demoLink || "."} target="blank">
        <img
          className={!demoLink ? projectStyles.disable : undefined}
          src={`https:${image.file.url}`}
          alt={title}
        ></img>
      </Link>{" "}
      <div className={projectStyles.description}>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default Project;

import React from "react";
import { Helmet } from "react-helmet";
import * as portfolioStyles from "./portfolio.module.scss";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { graphql, useStaticQuery } from "gatsby";
import Project from "../components/Project";
import Layout from "../components/Layout";
function Portfolio() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProjectCard {
        nodes {
          title
          gitHub
          demoLink
          description {
            description
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Helmet title="Portfolio" />
      <Navigation />

      <section className={portfolioStyles.project_container}>
        <div className={portfolioStyles.project_wrapper}>
          {data.allContentfulProjectCard.nodes.map((node, index) => (
            <Project key={index} node={node} />
          ))}
        </div>
      </section>

      <Footer />
    </Layout>
  );
}

export default Portfolio;
